import React from "react"
import style from "./style.module.less"
import classNames from "classnames"
import AntTable from "@/components/AntTable"
import styled from "styled-components"
import { useIntl } from "react-intl"
import useBigScreen from "@/hooks/bigScreen"

function Index({currentCate,tbdata,tbhead}) {
  const bigScreen = useBigScreen(656)
  const intl = useIntl()
      
  return (
    <div className={classNames(style.main_container, style.clearfix)}>
      <AntTable bigScreen={bigScreen} tbdata={tbdata} hddata={tbhead}/> 
    </div>
  )
}

export default Index
