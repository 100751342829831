import React, { useEffect } from "react"
import { Table, Divider, Tag } from 'antd';
import "./style.css"

const setRowClassName=(record, index)=>{
  let className = '';
  className = index % 2 === 0 ? "oddRow" : "evenRow";
  return className;
}

const Index=(props)=> {
  return (
    <Table width={props.width} scroll={{x:true}}
    columns={props.hddata} dataSource={props.tbdata} pagination={false} rowClassName={setRowClassName}/>
  )
}
  
  export default Index













 

  


